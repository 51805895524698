import { useAppSelector } from "../../../../hooks/hook";
import "./Introduction.scss";
import dayjs from "dayjs";

const Introduction = () => {
  const contentState = useAppSelector((state) => state.contentReducer);
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const eventImage = localStorage.getItem('eventImage') || '';

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      } else if (key in other) {
        return other[key];   
      }
    }
    return key;
  };

  const getRegionCountryDate = () => {
    let formtStr = "";
    if (contentState.content.location?.city != undefined) {
      formtStr = contentState.content.location?.city;
    }

    if (contentState.content.location?.country != undefined) {
      if (formtStr) {
        formtStr = formtStr + ", ";
      }
      formtStr = formtStr + contentState.content.location?.country;
    }
    return formtStr;
  };
  return (
    <div className='introduction-container'>
      {(!eventImage && <section className='intro-holder'>
        <div className='text-bold'>
          {contentState.content.eventName} -{' '}
          {getTranslation(contentState.content.eventTherapeuticArea)}
          <br />
          {'(' + getTranslation(contentState.content.eventType) + ')'}
        </div>
        <div className='text-semi-bold'>
          {getTranslation('Digital Patient Case')} -{' '}
          {getTranslation(contentState.content.gender) + ' ' + contentState.content.patientCaseText}
        </div>
        <div className='text-bold'>
          {getRegionCountryDate()}
          <br />
          {contentState.content.location?.region + ' ' + getTranslation('Region')}
        </div>
        <div className='text-semi-bold'>
          {dayjs(contentState.content.eventData).format('MMMM D, YYYY')}
        </div>
      </section>)}
      {(eventImage && <section className='intro-custom-background'>
        <div className='intro-image-holder'>
          <img
            className='intro-custom-img'
            src={`${process.env.REACT_APP_IMAGE_URL}${eventImage}`}
            alt='BoehringerIngelheim'
          />
        </div>
      </section>)}
    </div>
  );
};

export default Introduction;
