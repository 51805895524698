import classNames from "classnames";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { disclaimerAcceptedData } from "../../../../redux/thunk";
import { Link } from "react-router-dom";
import "./Disclaimer.scss";

const Disclaimer = () => {
  const [accpeted, setAccepted] = useState(false);
  const dispatch = useAppDispatch();
  const contentState = useAppSelector((state) => state.contentReducer);
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const handleDisclaimer = (event) => {
    if (event.target.checked) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  };

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      }
    }
    return key;
  };

  const moveToCase = () => {
    setAccepted(false);
    dispatch(disclaimerAcceptedData());
  };

  const attachLink = (content) => {
    const regexPattern = /\(link=.*, text=[a-zA-Z\s]*\)/gm;
    const results = content.split(regexPattern);
    if (results.length == 2) {
      return (
        <label className="disclaimer-label">
          {results[0]}
          <Link target={"_blank"} rel="noopener noreferrer" to={`../tc`}>
            {getTranslation("Terms of Use")}
          </Link>
          {results[1]}
        </label>
      );
    } else {
      return <label className="disclaimer-label">{results}</label>;
    }
  };

  return (
    <div className="disclaimer-content">
      <div className="disclaimer-content-checkbox">
        <input type="checkbox" onChange={handleDisclaimer} />
        {contentState?.content?.customDisclaimer
          ? attachLink(contentState?.content?.customDisclaimer) 
          : attachLink(contentState?.content?.text)}
      </div>
      <button
        className={classNames(
          accpeted ? "cursor: default;" : "cursor: no-drop;",
          ""
        )}
        disabled={!accpeted}
        onClick={moveToCase}
      >
        {getTranslation("OK")}
      </button>
    </div>
  );
};

export default Disclaimer;
