import "./terms.scss";
import medqplogo from "../../assets/images/medqp-logo.svg";
const TermsAndConditions = () => {
  const heading = "Terms of Use";
  const content = {
    "General Legend on Terms of Use": [
      "BY ACCESSING THIS SITE, YOU SIGNIFY YOUR AGREEMENT WITH, AND UNDERSTANDING OF, THE FOLLOWING TERMS OF USE AND LEGAL INFORMATION PERTAINING TO BOTH THIS SITE AND ANY MATERIAL ON IT. THIS SITE IS OFFERED TO YOU CONDITIONED ON YOUR ACCEPTANCE WITHOUT MODIFICATION OF THE TERMS, CONDITIONS, AND NOTICES CONTAINED HEREIN. YOUR USE OF THIS SITE CONSTITUTES YOUR AGREEMENT TO ALL SUCH TERMS, CONDITIONS AND NOTICES. MEDQA RESERVES THE RIGHT TO CHANGE THE TERMS, CONDITIONS, AND NOTICES UNDER WHICH THIS SITE IS OFFERED.",
      "ALL MATERIALS ON THIS SITE ARE MEANT TO BE REVIEWED IN THEIR ENTIRETY, INCLUDING ANY FOOTNOTES, LEGAL DISCLAIMERS, RESTRICTIONS OR DISCLOSURES, AND ANY COPYRIGHT OR PROPRIETARY NOTICES. ANY DISCLAIMERS, RESTRICTIONS, DISCLOSURES OR HEDGE CLAUSES APPLY TO ANY PARTIAL DOCUMENT OR MATERIAL IN THE SAME MANNER AS THEY DO THE WHOLE AND WILL BE DEEMED INCORPORATED IN THE PORTION OF ANY MATERIAL OR DOCUMENT THAT YOU CONSULT OR DOWNLOAD.",
    ],
    "Ownership of Site and Copyright": [
      "THE MEDQA WEBSITE IS OWNED BY THE BESTDRAFT COMPANY, DBA “MEDQA”. THE MEDQA’S WEBSITE AND ANY AND ALL ACCOMPANYING SCREENS, INFORMATION, MATERIALS, USER DOCUMENTATION, USER INTERFACES, IMAGES, ARRANGEMENTS OF INFORMATION, RELATED SOFTWARE AND OTHER PROPRIETARY PROPERTY OF THE BESTDRAFT COMPANY OR ITS LICENSORS ACCESSIBLE VIA THE WEBSITE IS AND SHALL REMAIN THE EXCLUSIVE PROPERTY OF BESTDRAFT COMPANY AND ITS LICENSORS, AS THE CASE MAY BE. ALL RIGHTS TO THE WEBSITE REMAIN WITH BESTDRAFT COMPANY OR ITS LICENSORS. THIS SITE IS FOR YOUR PERSONAL AND COMMERCIAL USE. YOU MAY NOT MODIFY, COPY, DISTRIBUTE, TRANSMIT, DISPLAY, PERFORM, REPRODUCE, PUBLISH, LICENSE, CREATE DERIVATIVE WORKS FROM, TRANSFER OR SELL ANY INFORMATION, SOFTWARE, PRODUCTS OR SERVICES OBTAINED FROM THIS SITE.",
    ],
    "Unauthorized Access": [
      "THIS SITE IS NOT ABSOLUTELY PROTECTED AGAINST UNAUTHORIZED THIRD PARTIES. YOU ACKNOWLEDGE THAT ANY INFORMATION PROVIDED THROUGH THE INTERNET MAY BE POTENTIALLY ACCESSED BY UNAUTHORIZED THIRD PARTIES. ALTHOUGH MEDQA WILL MAKE REASONABLE EFFORTS TO PROTECT THE PRIVACY OF USERS OF THIS SITE, NO GUARANTEE CAN BE MADE THAT UNAUTHORIZED THIRD PARTIES WILL NOT ACCESS THE INFORMATION CONTAINED ON THE WEBSITE. YOU ACKNOWLEDGE THAT MEDQA IS NOT RESPONSIBLE FOR NOTIFYING YOU THAT UNAUTHORIZED THIRD PARTIES HAVE GAINED SUCH ACCESS OR THAT ANY DATA HAS BEEN OTHERWISE COMPROMISED DURING TRANSMISSION ACROSS COMPUTER NETWORKS OR TELECOMMUNICATIONS FACILITIES, INCLUDING, BUT NOT LIMITED TO, THE INTERNET",
    ],
    "Passwords and Security": [
      "YOU ARE RESPONSIBLE FOR THE CONFIDENTIALITY AND USE OF YOUR PASSWORD. YOUR PASSWORD IS AN IMPORTANT MEANS OF PROTECTION FOR YOU. YOU AGREE TO CONTACT US IMMEDIATELY IF YOU BELIEVE THAT AN UNAUTHORIZED PERSON HAS OBTAINED ACCESS TO YOUR PASSWORD.",
    ],
    Links: [
      "MEDQA HAS NOT REVIEWED UNAFFILIATED SITES LINKED TO THIS SITE, IF ANY, AND IS NOT RESPONSIBLE FOR THE CONTENT OF OFF-SITE PAGES OR ANY OTHER SITE LINKED OR LINKING TO THE SITE. YOUR LINKING TO ANY OFF-SITE PAGES OR OTHER SITES IS AT YOUR OWN RISK. MEDQA MAKES NO REPRESENTATIONS WHATSOEVER ABOUT THE OPINIONS OF ANY THIRD-PARTY APPEARING ON A LINKED SITE, NEITHER REGULARLY MONITORS NOR HAS CONTROL OVER THE CONTENTS OF SUCH SITES, AND DOES NOT ENDORSE, AND DISCLAIMS ALL RESPONSIBILITY FOR, THE CONTENT OF SUCH STATEMENTS OR WEBSITES.",
    ],
    "Privacy Policy": [
      'THE USER RECOGNIZES THAT NON-PUBLIC INFORMATION CONCERNING THE USER DISCLOSED BY THE USER TO MEDQA OR OTHER AGENTS (THE "INFORMATION") (SUCH AS THE USER’S NAME, EMAIL ADDRESS, PHONE NUMBER) MAY BE DISCLOSED (I) TO THIRD-PARTY SERVICE PROVIDERS WHO MAY BE PROVIDING MARKETING SERVICES TO MEDQA PROVIDED THAT SUCH PERSONS MUST AGREE TO PROTECT THE CONFIDENTIALITY OF THE INFORMATION AND USE THE INFORMATION ONLY FOR THE PURPOSES OF PROVIDING SERVICES TO MEDQA AND (II) AS OTHERWISE REQUIRED OR PERMITTED BY LAW. MEDQA RESTRICTS ACCESS TO THE INFORMATION TO THEIR EMPLOYEES WHO NEED TO KNOW THE INFORMATION TO PROVIDE SERVICE AND MAINTAIN PHYSICAL, ELECTRONIC AND PROCEDURAL SAFEGUARDS THAT COMPLY WITH U.S. FEDERAL STANDARDS TO GUARD THE INFORMATION.',
    ],
    Assistance: [
      "IF YOU NEED ASSISTANCE WITH THE WEBSITE, OR IF YOU NEED TO COMMUNICATE WITH MEDQA, YOU MAY CONTACT US AT INFO AT MEDQAAPP DOT COM.",
    ],
    "No Warranty or Reliance": [
      'THE WEBSITE, INCLUDING INFORMATION AND MATERIALS CONTAINED IN THE WEBSITE, TEXT, GRAPHICS, SOFTWARE, LINKS AND OTHER ITEMS ARE PROVIDED "AS IS," "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW. WITHOUT LIMITATION, MEDQA DOES NOT WARRANT THE ACCURACY, ADEQUACY, COMPLETENESS, RELIABILITY, TIMELINESS OR AVAILABILITY OF THE WEBSITE OR ANY INFORMATION ON THIS SITE, AND EXPRESSLY DISCLAIMS LIABILITY FOR ERRORS OR OMISSIONS ON THE WEBSITE. THERE IS NO WARRANTY OF MERCHANTIBILITY, NO WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, NO WARRANTY OF NON-INFRINGEMENT, NO WARRANTY OF ANY KIND, IMPLIED, EXPRESSED OR STATUTORY, IN CONJUNCTION WITH THE WEBSITE. ANY CONTENTS ON THIS SITE ARE SUBJECT TO CHANGE WITHOUT NOTICE. MEDQA FURTHER ASSUMES NO RESPONSIBILITY FOR, AND MAKES NO WARRANTIES THAT, FUNCTIONS CONTAINED AT THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PLEASE NOTE THAT SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.',
    ],
    "Limitation of Liability": [
      "IN NO EVENT WILL MEDQA BE LIABLE FOR ANY DAMAGES, OR FOR REPAIRS OR CORRECTIONS THAT MUST BE PERFORMED, TO OR ON YOUR COMPUTER, PERSON OR OTHER PROPERTY, INCLUDING, WITHOUT LIMITATION, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THE WEBSITE OR USE THEREOF OR THE INABILITY BY ANY PARTY TO USE SUCH SITE, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE, EVEN IF MEDQA, OR REPRESENTATIVES THEREOF, ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES.",
    ],
  };
  return (
    <div className="terms-container">
      <div className="terms-logo">
        <img className="header-logo" src={medqplogo} alt="MEDQP Logo" />
      </div>
      <div className="terms-heading">{heading}</div>
      <div className="content">
        {Object.keys(content).map((key) => {
          return (
            <div>
              <div className="terms-content-heading">{key}</div>
              {content[key].map((value) => {
                return <p className="terms-content-value">{value}</p>;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TermsAndConditions;
