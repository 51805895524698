import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import { setFeedback } from "../../../../../redux/reducers/surveySlice";
import { caseData, surveyData } from "../../../../../redux/thunk";
import "./SurveyFeedback.scss";

const SurveyFeedback = () => {
  const dispatch = useAppDispatch();
  const [feedback, setFeedbackState] = useState("");
  const contentState = useAppSelector((state) => state.contentReducer);
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const [submitted, setSubmitted] = useState(false);

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      } else if (key in other) {
        return other[key];
      }
    }
    return key;
  };

  const textAreaValue = (event) => {
    setFeedbackState(event.target.value);
  };
  const handleFeedback = () => {
    setSubmitted(true);
    dispatch(setFeedback(feedback));
    dispatch(surveyData()).then(dispatch(caseData()));
  };
  return (
    <div className="rating-survey-holder">
      <div className="survey-feedback-header">
        <label htmlFor="feedback">{getTranslation(contentState.header.additional)}</label>
      </div>
      <div className="rating-text-area-holder">
        <textarea
          className="rating-text-area"
          id="feedback"
          name="feedback"
          rows="10"
          maxLength={500}
          onChange={textAreaValue}
          required
        ></textarea>
      </div>
      <div className="rating-submit-button">
        <button disabled={feedback.length == 0 && !submitted} onClick={handleFeedback}>
          {getTranslation('Submit')}
        </button>
      </div>
    </div>
  );
};

export default SurveyFeedback;
