import { useAppSelector } from "../../../../hooks/hook";
import "./Case.scss";

const Case = () => {
  const contentState = useAppSelector((state) => state.contentReducer);
  const content = contentState.content;

  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const other = dictionaryState.other;
  const keyWords = dictionaryState.other;

  const getTranslation = (key) => {
    if (keyWords.name != "English") {
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      }
    }
    return key;
  };
  return (
    <div className="case-details-container">
      {Object.keys(content).map((key, index) => {
        return (
          <div className="case-details" key={index}>
            <div className="case-details-header">{getTranslation(key)}</div>
            <div className="case-details-value">
              {content[key].map((val, index) => {
                return <p>{val}</p>;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Case;
