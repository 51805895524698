import { configureStore } from "@reduxjs/toolkit";
import alertReducer from "./reducers/alertSlice";
import eventReducer from "./reducers/eventSlice";
import footerReducer from "./reducers/footerSlice";
import contentReducer from "./reducers/contentSlice";
import surveyReducer from "./reducers/surveySlice";
import actionReducer from "./reducers/actionSlice";
import dictionaryReducer from "./reducers/dictonarySlice";

export const store = configureStore({
  reducer: {
    alertReducer,
    eventReducer,
    footerReducer,
    contentReducer,
    surveyReducer,
    actionReducer,
    dictionaryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
