import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { store } from "./redux/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TermsAndConditions from "./components/terms/terms";

const router = createBrowserRouter([
  {
    path: "/:eventId",
    element: <App />,
  },
  {
    path: "/tc",
    element: <TermsAndConditions></TermsAndConditions>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <App /> */}
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
