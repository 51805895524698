import "./Settings.scss";
import settingSVG from "../../../../assets/images/font-size.svg";

const Settings = () => {
  const clickHandler = () => {};
  return (
    <div onClick={clickHandler}>
      <img src={settingSVG} className="footer-setting-img"></img>
    </div>
  );
};
export default Settings;
