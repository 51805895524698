import medqplogo from "../../assets/images/medqp-logo.svg";
import { useAppSelector } from '../../hooks/hook';
import './Header.scss';
import { useEffect } from 'react';

const Header = () => {
  const contentState = useAppSelector((state) => state.contentReducer);
  let eventLogo = localStorage.getItem('eventLogo') || '';
  
  useEffect(() => {
    if (contentState?.eventBackground) {
      localStorage.setItem('eventBackground', contentState?.eventBackground);
    }
    if(contentState?.eventLogo) {
      eventLogo = contentState.eventLogo;
      localStorage.setItem('eventLogo', eventLogo);
    }
    if(contentState?.eventImage) {
      localStorage.setItem('eventImage', contentState?.eventImage);
    }
  }, [contentState]);
  return (
    <section className='header-container'>
      <header className='header-content'>
        <img className='header-logo' src={medqplogo} alt='MEDQP Logo' />
        {(eventLogo && eventLogo !== '') && (
          <img
              className='custom-header-bi-logo'
              src={`${process.env.REACT_APP_IMAGE_URL}${eventLogo}`}
              alt='Event Logo'
            />
        )}
      </header>
    </section>
  );
};

export default Header;
