import "./Alert.scss";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../../redux/reducers/alertSlice";
import { useEffect } from "react";
import classNames from "classnames";
import { useAppSelector } from "../../hooks/hook";

const Alert = () => {
  const dispatch = useDispatch();
  const { message, isDisplayed } = useSelector((state) => state.alertReducer);

  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const other = dictionaryState.other;
  const keyWords = dictionaryState.other;

  const getTranslation = (key) => {
    if (keyWords.name != "English") {
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      } else if (key in other) {
        return other[key];
      }
    }
    return key;
  };

  useEffect(() => {
    let timeoutId;
    if (isDisplayed) {
      timeoutId = setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [message, isDisplayed]);

  return (
    <section id="alert-section" className={classNames("alert-section")}>
      <div className={classNames("alert-holder")}>
        <span className="alert-text">{getTranslation(message)}</span>
      </div>
    </section>
  );
};

export default Alert;
