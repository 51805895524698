import { AXIOS } from "./axios";

export const dictionaryControllerURL = "/dictionary/";
export const pollControllerURL = "/poll/";

export const connectClientURL =
  AXIOS.defaults.baseURL + pollControllerURL + "connect/";
export const eventCaseURL =
  AXIOS.defaults.baseURL + pollControllerURL + "event/case/";
export const eventURL = AXIOS.defaults.baseURL + pollControllerURL + "event/";
export const eventStatusURL =
  AXIOS.defaults.baseURL + pollControllerURL + "event/status/";
export const submitURL = AXIOS.defaults.baseURL + pollControllerURL + "submit";
export const caseURL = AXIOS.defaults.baseURL + pollControllerURL + "case/";
export const surveyURL =
  AXIOS.defaults.baseURL + pollControllerURL + "survey/submit";
export const disclaimerURL =
  AXIOS.defaults.baseURL + pollControllerURL + "clients/";

export const languageDictionaryURL =
  AXIOS.defaults.baseURL + pollControllerURL + "case/dictionary/";

export const sessionKeyForTerms = "terms";
export const sessionKeyForClientId = "mqpId";
export const sessionKeyForListening = "isListening";
export const sessionKeyForIsListening = "listening";
export const sessionKeyForNA = "na";

export const SURVEY_TYPE = {
  RATING: "rating",
  FEEDBACK: "feedback",
};

export const POLL_DATA_TYPE = {
  HEARTBEAT: "heartbeat",
  EVENT_EXPIRED: "event_expired",
  EVENT_NOT_FOUND: "event_not_found",
  ERROR: "error",
  NOTIFICATION: "notification",
  HANDSHAKE: "handshake",
  REHANDSHAKE: "rehandshake",
  CASE: "case",
  INFO: "INFO",
  POLL: "poll",
  POLL_CLOSE: "poll_close",
  SURVEY: "survey",
  DISCLAIMER: "disclaimer",
  INTRODUCTION: "introduction",
  START_OVER: "start_over",
};

export const CHAPTER_TYPE = {
  INTERVIEW: "interview",
  IMAGE_INTERATCTIVE: "imageInteractive",
  GROUPS: "groups",
  NO_GROUPS: "noGroups",
  RANKING: "ranking",
  MULTIPLE_CHOICE: "multipleChoice",
};

export const CHAPTER_SELECTION_TYPE = {
  CHECKBOX: "checkbox",
  CHECKBOXGROUP: "checkbox-group",
  OPTION: "option",
  OPTIONGROUP: "option-group",
  RANKING: "ranking",
  SURVEY: "survey",
};

export const NOTIFICATION_NOTES = {
  SOME_ISSUE_OCCURED: "Some Issue Occured!",
  CONNECTED: "Connected to Poll!!",
  POLL_THANKS: "Thank you for submitting your feedback!",
  POLL_ANSWER_THANK_NOTE: "Thank you for submitting your vote!",
  POLL_EVENT_ID_NOT_FOUND: "No Event Found!!",
  POLL_CASE_ID_NOT_FOUND: "No Case For the Event Found!!",
  POLL_DISCLAIMER_THANKS_NOTE:
    "Thank you for participating in the MedQP experience - the next step in medical continuing education.",
};

export const EVENT_STATUS = {
  NOT_STARTED: "NOT STARTED",
  STARTED: "STARTED",
  POLLING: "POLLING",
  SURVEY: "SURVEY",
};
