import "./ContainerHeader.scss";
import { useAppSelector } from "../../../hooks/hook";
import classNames from "classnames";
import { POLL_DATA_TYPE } from "../../../api/constants";
import man from "./man.svg";
import women from "./woman.svg";

const ContainerHeader = () => {
  const contentState = useAppSelector((state) => state.contentReducer);
  const eventState = useAppSelector((state) => state.eventReducer);
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      } else if (key in other) {
        return other[key];
      }
    }
    return key;
  };

  const getHeaderValues = (headingType) => {
    // const otherText = dictionaryState.other;

    if (
      eventState.currentScreenType === POLL_DATA_TYPE.INTRODUCTION ||
      eventState.currentScreenType === POLL_DATA_TYPE.EVENT_NOT_FOUND ||
      eventState.currentScreenType === POLL_DATA_TYPE.EVENT_EXPIRED ||
      eventState.currentScreenType === POLL_DATA_TYPE.ERROR
    ) {
      if (headingType === "main") {
        // return otherText[contentState.header.heading];
        return getTranslation(contentState.header.heading);
      } else if (headingType === "submain") {
        // return otherText[contentState.header.subheading];
        return getTranslation(contentState.header.subheading);
      }
    } else if (eventState.currentScreenType === POLL_DATA_TYPE.DISCLAIMER) {
      if (headingType === "main") {
        // return otherText[contentState.header.heading];
        return getTranslation(contentState.header.heading);
      } else if (headingType === "submain") {
        return getTranslation(contentState.header.subheading);
      }
    } else if (eventState.currentScreenType === POLL_DATA_TYPE.SURVEY) {
      if (headingType === "main") {
        return getTranslation(contentState.header.title);
      } else if (headingType === "submain") {
        return getTranslation(contentState.header.question);
      }
    } else if (eventState.currentScreenType === POLL_DATA_TYPE.POLL) {
      if (headingType === "main") {
        return contentState.header.title;
      } else if (headingType === "submain") {
        return contentState.header.question;
      }
    } else if (eventState.currentScreenType === POLL_DATA_TYPE.CASE) {
      if (headingType === "main") {
        return (
          getTranslation(contentState.header.patient_details.salution) +
          " " +
          contentState.header.patient_details.name
        );
      } else if (headingType === "submain") {
        return (
          // otherText[contentState.header.patient_details.gender] +
          getTranslation(contentState.header.patient_details.gender) +
          ", " +
          contentState.header.patient_details.age
        );
      } else if (headingType === "visit") {
        // return otherText[contentState.header.patient_details.visit];
        return getTranslation(contentState.header.patient_details.visit);
      } else if (headingType === "image") {
        if (contentState.header.avatar == "") {
          if (contentState.header.patient_details.gender == "Female") {
            return women;
          } else {
            return man;
          }
        }
        return process.env.REACT_APP_IMAGE_URL + contentState.header.avatar;
      }
    }
    return "";
  };

  const mainHeading = getHeaderValues("main");
  const subHeading = getHeaderValues("submain");
  const visitHeading =
    eventState.currentScreenType == POLL_DATA_TYPE.CASE
      ? getHeaderValues("visit")
      : "";

  return (
    <div className="container-header">
      {(eventState.currentScreenType === POLL_DATA_TYPE.INTRODUCTION ||
        eventState.currentScreenType === POLL_DATA_TYPE.EVENT_NOT_FOUND ||
        eventState.currentScreenType === POLL_DATA_TYPE.EVENT_EXPIRED ||
        eventState.currentScreenType === POLL_DATA_TYPE.ERROR) && (
        <div className="container-introduction-header">
          <span className="container-header-main">{mainHeading}</span>
          <span className="container-header-main">{subHeading}</span>
        </div>
      )}
      {(eventState.currentScreenType === POLL_DATA_TYPE.DISCLAIMER ||
        eventState.currentScreenType === POLL_DATA_TYPE.SURVEY ||
        eventState.currentScreenType === POLL_DATA_TYPE.POLL) && (
        <div id="disclaimer_header" className="container-disclaimer-header">
          <span className="container-header-main">{mainHeading}</span>
          <span className="container-header-sub-heading">{subHeading}</span>
        </div>
      )}{" "}
      {eventState.currentScreenType === POLL_DATA_TYPE.CASE && (
        <div className="container-header-case">
          {/* <div
            style={{
              width: "5.5em",
              height: "6.6em",
              background: getHeaderValues("image"),
            }}
          ></div> */}
          <img
            className="container-header-case-avatar"
            src={getHeaderValues("image")}
          ></img>
          <div className="container-header-case-details">
            <div className="container-header-main">{mainHeading}</div>
            <div className="container-header-case-sub-details">
              <span className="container-header-sub-heading">{subHeading}</span>
              <span className="container-header-sub-smaller-heading">
                {visitHeading + " " + getTranslation("Visit")}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContainerHeader;
