import ContainerContent from "./content/ContainerContent";
import ContainerHeader from "./header/ContainerHeader";
import "./Container.scss";

const Container = () => {
  return (
    <section className='container'>
      <section className='container-header-holder'>
        <ContainerHeader></ContainerHeader>
      </section>
      <section className='container-content-holder'>
        <ContainerContent></ContainerContent>
      </section>
    </section>
  );
};

export default Container;
