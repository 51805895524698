import { useEffect, useRef } from "react";
import { connectClient } from "../redux/thunk";
import { useAppDispatch, useAppSelector } from "./hook";

export const sideEffects = () => {
  const dispatch = useAppDispatch();
  const eventState = useAppSelector((state) => state.eventReducer);

  function useInterval(callback: any, delay: any) {
    const savedCallback = useRef(() => {});

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useEffect(() => {
    dispatch(connectClient());
  }, []);

  useInterval(() => {
    if (eventState.isListening) {
      if ((performance.now() - eventState.ping) / 1000 > 5) {
        console.log("Handshake not received");
        dispatch(connectClient());
      }
    }
  }, 1000);
};
