import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  patientRating: 0,
  medqpRating: 0,
  feedback: "",
};
export const surveySlice = createSlice({
  name: "survey",
  initialState: initialState,
  reducers: {
    setRatings: (state, message) => {
      return {
        ...state,
        patientRating: message.payload.patientRating,
        medqpRating: message.payload.medqpRating,
      };
    },
    setFeedback: (state, message) => {
      return {
        ...state,
        feedback: message.payload,
      };
    },
  },
});

export const {setRatings, setFeedback} = surveySlice.actions;

export default surveySlice.reducer;