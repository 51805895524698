import dayjs from 'dayjs';
import { useAppSelector } from "../../hooks/hook";
import Settings from "../container/content/settings/Settings";
import "./Footer.scss";

const Footer = () => {
  const footerState = useAppSelector((state) => state.footerReducer);
  const getRegionCountryDate = () => {
    let formtStr = undefined;
    if (footerState.region != undefined) {
      formtStr = footerState.region;
    }

    if (footerState.country != undefined) {
      if (formtStr) {
        formtStr = formtStr + ", ";
      }
      formtStr = formtStr + footerState.country;
    }

    if (footerState.date != undefined) {
      if (formtStr) {
        formtStr = formtStr + " - ";
      }
      formtStr = formtStr + dayjs(footerState.date).format("MMMM DD, YYYY");
    }
    return formtStr;
  };

  return (
    <section className="footer-container">
      <div className="footer-setting-container">
        <Settings></Settings>
      </div>
      <div className="footer-text-holder">
        <span className="footer-meeting-name">{footerState.meetingName}</span>
        <span className="footer-meeting-place">{getRegionCountryDate()}</span>
      </div>
    </section>
  );
};

export default Footer;
