import classNames from "classnames";
import "./App.css";
import Alert from "./components/alert/Alert";
import Container from "./components/container/Container";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import { useAppSelector } from "./hooks/hook";
import { sideEffects } from "./hooks/sideEffects";
import { useEffect } from 'react';

const App = () => {
  sideEffects();
  const alertState = useAppSelector((state) => state.alertReducer);
  const footerState = useAppSelector((state) => state.footerReducer);
  useEffect(() => {
    // Add an event listener for the beforeunload event
    const handleBeforeUnload = () => {
      // Clear the local storage
      localStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <section className="app-container">
      <section className="app-header">
        <Header></Header>
      </section>
      <section
        className={classNames(
          "app-popup",
          alertState.isDisplayed ? "show-element" : "hide-element"
        )}
      >
        {<Alert></Alert>}
      </section>
      <section className="app-body">
        <Container></Container>
      </section>
      <section className="app-footer">
        {footerState.show && <Footer></Footer>}
      </section>
    </section>
  );
};

export default App;
