import classNames from "classnames";
import { useState } from "react";
import {
  CHAPTER_SELECTION_TYPE,
  CHAPTER_TYPE,
} from "../../../../api/constants";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { caseData, postPollData } from "../../../../redux/thunk";
import "./MultiChoiceGroup.scss";
import _ from "lodash";

const MultiChoiceGroup = () => {
  const dispatch = useAppDispatch();
  const contentState = useAppSelector((state) => state.contentReducer);
  const eventState = useAppSelector((state) => state.eventReducer);
  const options = contentState.content.options;
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const [submitted, setSubmitted] = useState(false);

  const getTranslation = (key, toUpper) => {
    let translation = key;
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        translation = other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        translation = other[_.camelCase(key)];
      } else if (key in other) {
        translation = other[key];
      }
    }
    return toUpper ? _.toUpper(translation) : translation;
  };

  var output = options.reduce((result, option) => {
    var category = (result[option.category] = result[option.category] || {});
    var subcategory = (category[option.subcategory] =
      category[option.subcategory] || []);
    subcategory.push(option);
    return result;
  }, {});

  let additional = [];

  if (contentState.header.type === CHAPTER_TYPE.IMAGE_INTERATCTIVE) {
    additional = contentState.content.additional;
  }

  const [checked, setChecked] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const isSelected = (id) => {
    return checked.indexOf(id) !== -1;
  };

  const handleSubmission = (event) => {
    setSubmitted(true);
    const pushResult = {
      pollId: contentState.pollId,
      clientId: eventState.clientId,
      type: contentState.header.type,
      answers: checked,
    };
    dispatch(postPollData(pushResult)).then(dispatch(caseData()));
  };

  return (
    <div className="multi-choice-group-container">
      <div
        className={classNames(
          "multi-choice-values-group-container",
          "multi-choice-values-group-top"
        )}
      >
        {Object.keys(output).map((category, ci) => {
          return (
            <div key={ci} className="main-group">
              <div className="group-heading">
                {getTranslation(category, true)}
              </div>
              <div className="sub-group">
                {Object.keys(output[category]).map((subcategory, si) => {
                  return (
                    <div className="sub-group-internal" key={si}>
                      <div className="multi-choice-sub-group-heading">
                        {getTranslation(subcategory, true)}
                      </div>
                      {output[category][subcategory].map((choice, vi) => {
                        return (
                          <div
                            className={classNames(
                              "multi-choice-sub-group",
                              isSelected(choice.id)
                                ? "selection-background"
                                : ""
                            )}
                            key={vi}
                          >
                            <input
                              value={choice.id}
                              type="checkbox"
                              onChange={handleCheck}
                            />
                            <label className="choice-label-multi-group">
                              {getTranslation(choice.option.replace("***", ""), false)}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={classNames(
          "multi-choice-values-group-container",
          "multi-choice-values-group-bottom",
          "mutli-choice-additional"
        )}
      >
        <div className="main-group">
          <div className="group-heading-additional">
            {getTranslation("ADDITIONAL EXAM", true)}
          </div>
          {additional.map((choice, index) => {
            return (
              <div
                className={classNames("multi-choice-sub-group", "")}
                key={index}
              >
                <input
                  className={isSelected(choice.id) ? "checkbox-tick-mark" : ""}
                  value={choice.id}
                  type="checkbox"
                  onChange={handleCheck}
                />
                <label
                  className={classNames(
                    "choice-label-multi-group",
                    isSelected(choice.id) ? "selection-background" : ""
                  )}
                >
                  {getTranslation(choice.option.replace("***", ""), false)}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <button
        className="button-multi-choice-position"
        disabled={!checked.length > 0 && !submitted}
        onClick={handleSubmission}
      >
        {getTranslation("Submit")}
      </button>
    </div>
  );
};

export default MultiChoiceGroup;
