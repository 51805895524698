import {
  CHAPTER_SELECTION_TYPE,
  CHAPTER_TYPE,
  POLL_DATA_TYPE,
} from "../../../api/constants";
import { useAppSelector } from "../../../hooks/hook";
import Disclaimer from "./disclaimer/Disclaimer";
import "./ContainerContent.scss";
import Introduction from "./introduction/Introduction";
import Ranking from "./ranking/Ranking";
import MultiChoice from "./multichoice/MultiChoice";
import Case from "./case/Case";
import Survey from "./survey/Survey";
import Action from "../../action/Action";
import MultiChoiceGroup from "./multichoice-group/MultiChoiceGroup";
import Error from "./error/errors";

const ContainerContent = () => {
  const eventState = useAppSelector((state) => state.eventReducer);
  const contentState = useAppSelector((state) => state.contentReducer);
  const actionState = useAppSelector((state) => state.actionReducer);
  const eventBackground = localStorage.getItem('eventBackground') || '';
  return (
    <div className='container-content'>
      {(eventBackground && (
          <div className='eventBackgroundContainer'>
            <img
              className='event_background'
              src={`${process.env.REACT_APP_IMAGE_URL}${eventBackground}`}
              alt='Event Background'
            />
          </div>
        ))}
      {eventState.currentScreenType === POLL_DATA_TYPE.INTRODUCTION && (
        <Introduction></Introduction>
      )}
      {eventState.currentScreenType === POLL_DATA_TYPE.DISCLAIMER && <Disclaimer></Disclaimer>}
      {eventState.currentScreenType === POLL_DATA_TYPE.POLL &&
        contentState.header.type === CHAPTER_TYPE.RANKING && <Ranking></Ranking>}
      {eventState.currentScreenType === POLL_DATA_TYPE.POLL &&
        contentState.header.type != CHAPTER_TYPE.IMAGE_INTERATCTIVE &&
        contentState.header.type != CHAPTER_TYPE.RANKING && <MultiChoice></MultiChoice>}
      {eventState.currentScreenType === POLL_DATA_TYPE.POLL &&
        contentState.header.type === CHAPTER_TYPE.IMAGE_INTERATCTIVE && (
          <MultiChoiceGroup></MultiChoiceGroup>
        )}
      {eventState.currentScreenType === POLL_DATA_TYPE.SURVEY && <Survey></Survey>}{' '}
      {eventState.currentScreenType === POLL_DATA_TYPE.CASE && <Case></Case>}
      {(eventState.currentScreenType === POLL_DATA_TYPE.EVENT_NOT_FOUND ||
        eventState.currentScreenType === POLL_DATA_TYPE.EVENT_EXPIRED ||
        eventState.currentScreenType === POLL_DATA_TYPE.ERROR) && <Error></Error>}
      {actionState.isVisible && <Action></Action>}
    </div>
  );
};

export default ContainerContent;
