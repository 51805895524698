import { createSlice } from "@reduxjs/toolkit";
import { EVENT_STATUS } from "../../api/constants";
import { RootState } from "../store";

interface EventState {
  clientId: string;
  caseId: number;
  eventId: number;
  eventBackground: string;
  isListening: boolean;
  currentScreenType: string;
  currentScreenSubType: string;
  isPolling: false;
  status: string;
  ping: number;
}

const initialState: EventState = {
  clientId: "",
  caseId: -1,
  eventId: -1,
  eventBackground: "",
  isListening: false,
  currentScreenType: "",
  currentScreenSubType: "",
  isPolling: false,
  status: "",
  ping: 0,
};

export const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    setEvent: (state, action) => {
      return {
        ...state,
        caseId: action.payload.caseId,
        eventId: action.payload.eventId,
        eventBackground: action.payload.eventBackground,
      };
    },
    setIsPolling: (state, message) => {
      return {
        ...state,
        isPolling: message.payload,
      };
    },
    setPing: (state, message) => {
      return {
        ...state,
        ping: message.payload,
      };
    },
    setCaseID: (state, message) => {
      return {
        ...state,
        caseId: message.payload,
      };
    },
    setEventID: (state, message) => {
      return {
        ...state,
        eventId: message.payload,
      };
    },
    setClientID: (state, message) => {
      return {
        ...state,
        clientId: message.payload,
      };
    },
    setListening: (state, message) => {
      return {
        ...state,
        isListening: message.payload,
      };
    },
    setType: (state, message) => {
      return {
        ...state,
        currentScreenType: message.payload,
      };
    },
    setSubType: (state, message) => {
      return {
        ...state,
        currentScreenSubType: message.payload,
      };
    },
    setStatus: (state, message) => {
      return {
        ...state,
        status: message.payload,
      };
    },
  },
});

export const {
  setCaseID,
  setEvent,
  setEventID,
  setClientID,
  setListening,
  setIsPolling,
  setType,
  setSubType,
  setStatus,
  setPing,
} = eventSlice.actions;

export const selectEventId = (state: RootState) => state.eventReducer.eventId;

export const selectCaseId = (state: RootState) => state.eventReducer.caseId;

export const selectClientId = (state: RootState) => state.eventReducer.clientId;

export const selectIsListening = (state: RootState) =>
  state.eventReducer.isListening;

export const selectType = (state: RootState) =>
  state.eventReducer.currentScreenType;

export const selectSubType = (state: RootState) =>
  state.eventReducer.currentScreenSubType;

export default eventSlice.reducer;
