import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface FooterState {
  meetingName: string;
  region: string;
  country: string;
  date: string;
  show: boolean;
  fontPadding: number;
}

const initialState: FooterState = {
  meetingName: "",
  region: "",
  country: "",
  date: "",
  show: false,
  fontPadding: 0,
};

export const footerSlice = createSlice({
  name: "footer",
  initialState: initialState,
  reducers: {
    setFontPadding: (state, action) => {
      return {
        ...state,
        fontPadding: state.fontPadding + action.payload,
      };
    },
    setFooter: (state, action) => {
      return {
        ...state,
        meetingName: action.payload.meetingName,
        region: action.payload.region,
        country: action.payload.country,
        date: action.payload.date,
        show: action.payload.show,
      };
    },
    footerVisibility: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        show: action.payload,
      };
    },
  },
});

export const { setFooter, footerVisibility } = footerSlice.actions;

export const showFooter = (state: RootState) => state.footerReducer.show;

export default footerSlice.reducer;
