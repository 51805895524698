import { createSlice } from "@reduxjs/toolkit";
import { PatientContent } from "../../model/case.model";
import { EventContent } from "../../model/event.model";
import {
  CategoryHeader,
  HeaderWithSubHeader,
  PatientIntroductionHeader,
} from "../../model/header.model";
import { PollContent, SurveyContent } from "../../model/poll.model";
import { Notifications, TextValue } from "../../model/request-payload";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Root } from "react-dom/client";

interface ContentState {
  id: number;
  exclusivePolling: boolean;
  eventBackground: string;
  eventLogo: string;
  eventImage: string;
  header:
    | string
    | HeaderWithSubHeader
    | TextValue
    | CategoryHeader
    | PatientIntroductionHeader;
  content:
    | string
    | TextValue
    | PatientContent
    | EventContent
    | PollContent
    | SurveyContent[];
    
}

const initialContentState: ContentState = {
  id: 0,
  exclusivePolling: false,
  header: "",
  content: "",
  eventBackground: "",
  eventLogo: "",
  eventImage: "",
};

export const contentSlice = createSlice({
  name: "content",
  initialState: initialContentState,
  reducers: {
    setContent(state, action: PayloadAction<ContentState>) {
      return {
        ...state,
        pollId: action.payload.id,
        exclusivePolling: action.payload.exclusivePolling,
        header: action.payload.header,
        content: action.payload.content,
        eventBackground: action.payload?.eventBackground,
        eventLogo: action.payload?.eventLogo,
        eventImage: action.payload?.eventImage,
      };
    },
  },
});

export const { setContent } = contentSlice.actions;

export const selectPollId = (state: RootState) => state.contentReducer.id;
export const selectHeader = (state: RootState) => state.contentReducer.header;
export const selectContent = (state: RootState) => state.contentReducer.content;

export default contentSlice.reducer;
