export function getUuidFromUrl() {
  const splitUrl = window.location.pathname.split("/");
  return splitUrl[1] || "";
}

export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function setSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

export function getLocalStorageOrDefault(key, defaultValue) {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}
