import classNames from "classnames";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { caseData, postPollData } from "../../../../redux/thunk";
import "./Ranking.scss";

const Ranking = () => {
  const dispatch = useAppDispatch();
  const contentState = useAppSelector((state) => state.contentReducer);
  const eventState = useAppSelector((state) => state.eventReducer);
  const options = contentState.content.options;
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const [submitted, setSubmitted] = useState(false);

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      }
    }
    return key;
  };

  const getJoinedIDFromOption = (options) => {
    return options.map((option) => option.id).join(",");
  };

  const [ranking, setRanking] = useState(options);

  const handleDown = (e) => {
    let tempOptions = [...ranking];
    let srcIndex = Number(e.target.value);
    let targetIndex = srcIndex == tempOptions.length - 1 ? 0 : srcIndex + 1;
    let temp = tempOptions[targetIndex];
    tempOptions[targetIndex] = tempOptions[srcIndex];
    tempOptions[srcIndex] = temp;
    setRanking(tempOptions);
  };
  const handleUp = (e) => {
    let tempOptions = [...ranking];
    let srcIndex = Number(e.target.value);
    let targetIndex = srcIndex == 0 ? tempOptions.length - 1 : srcIndex - 1;

    let temp = tempOptions[targetIndex];
    tempOptions[targetIndex] = tempOptions[srcIndex];
    tempOptions[srcIndex] = temp;
    setRanking(tempOptions);
  };

  const handleSubmission = (event) => {
    setSubmitted(true);
    const pushResult = {
      pollId: contentState.pollId,
      clientId: eventState.clientId,
      type: contentState.header.type,
      answers: getJoinedIDFromOption(ranking),
    };
    dispatch(postPollData(pushResult)).then(dispatch(caseData()));
  };

  return (
    <div className='ranking-spliter-div'>
      <div className='ranking-holder-container'>
        {ranking && ranking.map((opt, index) => {
          return (
            <div className='ranking-holder' key={opt.id}>
              <div className='ranking-index'>{index + 1}</div>
              <div className='ranking-holder-span'>{opt.option}</div>
              <div className='ranking-change-button-container'>
                <button
                  className={classNames('ranking-change-button', 'ranking-up-button')}
                  value={index}
                  disabled={index == 0}
                  onClick={handleUp}></button>
                <button
                  className={classNames('ranking-change-button', 'ranking-down-button')}
                  value={index}
                  disabled={index == ranking.length - 1}
                  onClick={handleDown}></button>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className='button-multi-choice-position'
        disabled={submitted}
        onClick={handleSubmission}>
        {getTranslation('Submit')}
      </button>
    </div>
  );
};

export default Ranking;
