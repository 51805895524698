import { useEffect } from 'react';
import { SURVEY_TYPE } from "../../../../api/constants";
import { useAppSelector } from "../../../../hooks/hook";
import SurveyFeedback from "./feedback/SurveyFeedback";
import SurveyRating from "./rating/SurveyRating";
import "./Survey.scss";

const Survey = () => {
  const eventState = useAppSelector((state) => state.eventReducer);
  return (
    <div className="survey-container">
      {eventState.currentScreenSubType == SURVEY_TYPE.RATING && (
        <SurveyRating></SurveyRating>
      )}
      {eventState.currentScreenSubType == SURVEY_TYPE.FEEDBACK && (
        <SurveyFeedback></SurveyFeedback>
      )}
    </div>
  );
};

export default Survey;
