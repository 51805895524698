import classNames from "classnames";
import { POLL_DATA_TYPE } from "../../api/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { executeAction } from "../../redux/reducers/actionSlice";

const Action = () => {
  const dispatch = useAppDispatch();
  const actionState = useAppSelector((state) => state.actionReducer);
  //   className={buttonState.isVisible ? "button-visible" : "button-hidden"}
  return (
    <section className="action-section">
      <button
        disabled={actionState.isDisabled}
        onClick={dispatch(executeAction(POLL_DATA_TYPE.SURVEY))}
      >
        {actionState.text}
      </button>
    </section>
  );
};
export default Action;
