import classNames from "classnames";
import { useEffect, useState } from "react";
import { SURVEY_TYPE } from "../../../../../api/constants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import { setSubType } from "../../../../../redux/reducers/eventSlice";
import { setRatings } from "../../../../../redux/reducers/surveySlice";
import "./SurveyRating.scss";

const SurveyRating = () => {
  const dispatch = useAppDispatch();
  const contentState = useAppSelector((state) => state.contentReducer);
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);
  const [submitted, setSubmitted] = useState(false);

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      } else if (key in other) {
        return other[key];
      }
    }
    return key;
  };

  const groupByCategory = contentState.content?.reduce((group, content) => {
    const { category } = content;
    group[category] = group[category] ?? {};
    group[category] = content;
    return group;
  }, {});

  const ratingDivs = [];
  const initialRating = {};
  Object.keys(groupByCategory).forEach((key) => {
    initialRating[key] = -1;
  });
  const [rating, setRating] = useState(initialRating);
  const [selectedRating, setSelectedRating] = useState(2);

  const updateRating = (event) => {
    let updateValue = event.target.value.split(",");
    let prevRating = { ...rating };
    prevRating[updateValue[0]] = Number(updateValue[1]);
    setRating(prevRating);

    let selection = Object.values(prevRating).filter((v) => v < 0);
    setSelectedRating(selection.length);
  };

  const isRatingSelected = (key) => {
    return rating[key] >= 0;
  };

  const handleSurveyRating = () => {
    setSubmitted(true)
    dispatch(
      setRatings({
        patientRating: rating["Patient Case Presented"],
        medqpRating: rating["MedQP presenting Patient Case"],
      })
    );
    dispatch(setSubType(SURVEY_TYPE.FEEDBACK));
  };

  for (const [key, value] of Object.entries(groupByCategory)) {
    ratingDivs.push(
      <div className="rating-heading" key={key}>
        <div
          className={classNames(
            "rating-heading-text",
            isRatingSelected(key)
              ? "rating-heading-text-selected"
              : "rating-heading-text-unselected"
          )}
        >
          {getTranslation(key)}
        </div>
        <div className="rating-text-container">
          <span className="rating-text">
            {getTranslation(value.lowestValue)}
          </span>
          <span className="rating-text">
            {getTranslation(value.highestValue)}
          </span>
        </div>
        <div className="rating-star-container">
          {[...Array(5)].map((x, i) => (
            <button
              className={classNames(
                "rating-icon",
                rating[key] >= i + 1
                  ? "rating-icon-selected"
                  : "rating-icon-unselected"
              )}
              value={[key, i + 1]}
              key={i + 1}
              onClick={updateRating}
            ></button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="rating-holder">
      {ratingDivs}
      <div className="rating-submit-button">
        <button disabled={selectedRating && !submitted} onClick={handleSurveyRating}>
          {getTranslation("Submit")}
        </button>
      </div>
    </div>
  );
};

export default SurveyRating;
