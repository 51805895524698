import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  keywords: {},
  other: {},
};

export const dictonarySlice = createSlice({
  name: "dictonary",
  initialState: initialState,
  reducers: {
    setDictonary: (state, message) => {
      return {
        ...state,
        keywords: message.payload.keywords,
        other: message.payload.other,
      };
    },
    setKeywords: (state, message) => {
      return {
        ...state,
        keywords: message.payload,
      };
    },
    setOther: (state, message) => {
      return {
        ...state,
        other: message.payload,
      };
    },
  },
});

export const { setDictonary, setKeywords, setOther } = dictonarySlice.actions;

export default dictonarySlice.reducer;
