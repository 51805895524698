import { createSlice } from "@reduxjs/toolkit";
import { POLL_DATA_TYPE } from "../../api/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { RootState } from "../store";
import { surveyData } from "../thunk";

const initialState = {
  text: "",
  isDisabled: true,
  isVisible: false,
  data: null,
  action: null,
};

export const actionSlice = createSlice({
  name: "action",
  initialState: initialState,
  reducers: {
    executeAction: (state, message) => {
     
    },
    setAction: (state, message) => {
      return {
        ...state,
        text: message.payload.text,
        isDisabled: message.payload.isDisabled,
        isVisible: message.payload.isVisible,
        data: message.payload.data,
        action: message.payload.action,
      };
    },
    setData: (state, message) => {
      return {
        ...state,
        data: message.payload.data,
      };
    },
    setDisabled: (state, message) => {
      return {
        ...state,
        isDisabled: message.payload,
      };
    },
    setVisible: (state, message) => {
      return {
        ...state,
        isVisible: message.payload,
      };
    },
    setText: (state, message) => {
      return {
        ...state,
        text: message.payload,
      };
    },
    setTextAndAction: (state, message) => {
      return {
        ...state,
        text: message.payload.text,
        action: message.payload.action,
      };
    },
  },
});

export const {
  executeAction,
  setData,
  setAction,
  setDisabled,
  setVisible,
  setText,
  setTextAndAction,
} = actionSlice.actions;

export const selectButtonDisabled = (state: RootState) =>
  state.actionReducer.isDisabled;
export const selectButtonVisible = (state: RootState) =>
  state.actionReducer.isVisible;
export const selectButtonText = (state: RootState) => state.actionReducer.text;

export default actionSlice.reducer;
