import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "../api/axios";
import {
  caseURL,
  connectClientURL,
  disclaimerURL,
  eventCaseURL,
  eventStatusURL,
  eventURL,
  EVENT_STATUS,
  languageDictionaryURL,
  NOTIFICATION_NOTES,
  POLL_DATA_TYPE,
  sessionKeyForClientId,
  sessionKeyForIsListening,
  sessionKeyForNA,
  sessionKeyForTerms,
  submitURL,
  surveyURL,
  SURVEY_TYPE,
} from "../api/constants";
import {
  getLocalStorageOrDefault,
  getSessionStorageOrDefault,
  getUuidFromUrl,
  setLocalStorage,
  setSessionStorage,
} from "../api/utils";
import { showAlert } from "./reducers/alertSlice";
import { setContent } from "./reducers/contentSlice";
import { setDictonary } from "./reducers/dictonarySlice";
import {
  setCaseID,
  setClientID,
  setEvent,
  setEventID,
  setIsPolling,
  setListening,
  setPing,
  setStatus,
  setSubType,
  setType,
} from "./reducers/eventSlice";
import { footerVisibility, setFooter } from "./reducers/footerSlice";
import { RootState } from "./store";

export const surveyData = createAsyncThunk(
  "/survey/feedback",
  async (_, { getState, dispatch }) => {
    let state = getState() as RootState;
    const data = {
      eventId: state.eventReducer.eventId,
      patientCaseRating: state.surveyReducer.patientRating,
      medqpRating: state.surveyReducer.medqpRating,
      feedback: state.surveyReducer.feedback,
    };
    const response = await AXIOS.post(surveyURL, data);
    if (response) {
      const data = response.data;
      dispatch(showAlert(NOTIFICATION_NOTES.POLL_THANKS));
    }
  }
);

export const caseData = createAsyncThunk(
  "/content/caseData",
  async (_, { getState, dispatch }) => {
    let s = getState() as RootState;

    const response = await AXIOS.get(
      caseURL +
        s.eventReducer.eventId +
        "/" +
        s.eventReducer.caseId +
        "/" +
        s.eventReducer.clientId
    );
    if (response) {
      const data = response.data;
      dispatch(setType(data.type));
      dispatch(setContent(data));
      dispatch(setIsPolling(false));
    }
  }
);

export const disclaimerAcceptedData = createAsyncThunk(
  "/event/disclaimer",
  async (_, { getState, dispatch }) => {
    let s = getState() as RootState;
    const response = await AXIOS.get(
      disclaimerURL + s.eventReducer.eventId + "/" + s.eventReducer.clientId
    );
    if (response) {
      const data = response.data;
      dispatch(showAlert(data));
      // dispatch(caseData());
    }
  }
);

export const fetchAndPublishFooterData = createAsyncThunk(
  "/event/footer",
  async (_, { getState, dispatch }) => {
    let s = getState() as RootState;
    if (s.eventReducer.caseId != -1) {
      const response = await AXIOS.get(
        eventURL + s.eventReducer.eventId + "/" + s.eventReducer.caseId
      );
      if (response) {
        const infoData = response.data;
        dispatch(
          setFooter({
            meetingName: infoData.content.eventName,
            region: infoData.content.location.city,
            country: infoData.content.location.country,
            date: infoData.content.eventData,
            show: false,
          })
        );
      }
    } else {
      dispatch(getCaseId()).then(async (d) => {
        const caseId = d.payload;
        dispatch(getDictionary());
        const response = await AXIOS.get(
          eventURL + s.eventReducer.eventId + "/" + caseId
        );
        if (response) {
          const infoData = response.data;
          dispatch(
            setFooter({
              meetingName: infoData.content.eventName,
              region: infoData.content.location.city,
              country: infoData.content.location.country,
              date: infoData.content.eventData,
              show: false,
            })
          );
        }
      });
    }
  }
);

export const eventIntroData = createAsyncThunk(
  "/event/intro",
  async (_, { getState, dispatch }) => {
    let s = getState() as RootState;
    console.log("Calling intro");
    dispatch(getCaseId()).then(async (d) => {
      const caseId = d.payload;
      dispatch(getDictionary());
      if (caseId != -1) {
        const response = await AXIOS.get(
          eventURL + s.eventReducer.eventId + "/" + caseId
        );
        if (response) {
          const infoData = response.data;
          dispatch(setType(infoData.type));
          dispatch(setContent(infoData));
          dispatch(
            setFooter({
              meetingName: infoData.content.eventName,
              region: infoData.content.location.city,
              country: infoData.content.location.country,
              date: infoData.content.eventData,
              show: false,
            })
          );
        }
      }
    });
  }
);

export const postPollData = createAsyncThunk(
  "/event/pollData",
  async (data, { getState, dispatch }) => {
    const s = getState() as RootState;
    try {
      if (data != undefined) {
        const response = await AXIOS.post(submitURL, data);
        if (response) {
          dispatch(showAlert(response.data));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const getCaseId = createAsyncThunk(
  "/event/case/id",
  async (_, { getState, dispatch }) => {
    const s = getState() as RootState;
    const eventId = s.eventReducer.eventId;
    const caseIDResponse = await AXIOS.get(eventCaseURL + eventId);
    const caseID = caseIDResponse.data;
    if (caseID && caseID != -1) {
      dispatch(setCaseID(caseID));
    }
    return caseID;
  }
);

export const getDictionary = createAsyncThunk(
  "/dictonary",
  async (_, { getState, dispatch }) => {
    const s = getState() as RootState;
    const caseId = s.eventReducer.caseId;
    const response = await AXIOS.get(languageDictionaryURL + caseId);
    const dictionary = response.data;
    if (dictionary) {
      dispatch(setDictonary(dictionary));
    }
  }
);

export const eventStatus = createAsyncThunk(
  "/event/status",
  async (_, { getState, dispatch }) => {
    let s = getState() as RootState;
    const response = await AXIOS.get(eventStatusURL + s.eventReducer.eventId);
    if (response) {
      const infoData = response.data;
      if (infoData) {
        dispatch(setStatus(infoData));
      }
    }
  }
);

export const connectClient = createAsyncThunk(
  "/event/clientConnect",
  async (_, { getState, dispatch }) => {
    try {
      const eventId = getUuidFromUrl();
      const state = getState() as RootState;

      let isListening = state.eventReducer.isListening;

      if (!isListening) {
        dispatch(setListening(true));
        dispatch(setPing(window.performance.now()));
        let clientId = getLocalStorageOrDefault(
          sessionKeyForClientId,
          sessionKeyForNA
        );
        console.log(clientId);

        let connectURL = connectClientURL + eventId;

        if (clientId == sessionKeyForNA) {
          console.log("No client Id attached");
          connectURL = connectURL + "/" + "0";
        } else {
          console.log("Client ID found dont Generate");
          connectURL = connectURL + "/" + clientId;
        }
        const events = new EventSource(connectURL);

        events.onerror = (event) => {
          dispatch(setListening(false));
          events.close();
          console.log("Reconnecting back....");
          setTimeout(() => dispatch(connectClient()), 5000);
        };

        events.onmessage = async (event) => {
          const parsedData = JSON.parse(event.data);
          if (parsedData) {
            if (parsedData.type === POLL_DATA_TYPE.HANDSHAKE) {
              console.log("Setting ClientId");
              dispatch(setType(parsedData.type));
              setLocalStorage(
                sessionKeyForClientId,
                JSON.stringify(parsedData.content.clientId)
              );
              dispatch(setClientID(parsedData.content.clientId));

              if (eventId) {
                dispatch(setEventID(eventId));
                console.log("From handshake");
                dispatch(getCaseId()).then((d) => {
                  dispatch(getDictionary());
                });
              }
            } else if (parsedData.type === POLL_DATA_TYPE.REHANDSHAKE) {
              console.log("Already Exists");
              dispatch(setType(parsedData.type));
              dispatch(setClientID(parsedData.content.clientId));

              if (eventId) {
                dispatch(setEventID(eventId));
                dispatch(getCaseId()).then((d) => {
                  dispatch(getDictionary());
                });
              }
            } else if (parsedData.type === POLL_DATA_TYPE.START_OVER) {
              console.log("From Start Over");
              dispatch(eventIntroData());
            } else if (parsedData.type === POLL_DATA_TYPE.INTRODUCTION) {
              console.log("From Introduction");
              dispatch(setType(parsedData.type));
              dispatch(setContent(parsedData));
              dispatch(
                setFooter({
                  meetingName: parsedData.content.eventName,
                  region: parsedData.content.location.city,
                  country: parsedData.content.location.country,
                  date: parsedData.content.eventData,
                  show: false,
                })
              );
            } else if (parsedData.type === POLL_DATA_TYPE.NOTIFICATION) {
              dispatch(showAlert(parsedData.content));
              let s = getState() as RootState;
              const response = await AXIOS.get(caseURL + s.eventReducer.caseId);
              if (response) {
                const data = response.data;
                dispatch(setType(data.type));
                dispatch(setContent(data));
              }
            } else if (
              parsedData.type === POLL_DATA_TYPE.SURVEY ||
              parsedData.type === POLL_DATA_TYPE.POLL
            ) {
              if (parsedData.type === POLL_DATA_TYPE.SURVEY) {
                dispatch(setSubType(SURVEY_TYPE.RATING));
              }

              if (parsedData.type === POLL_DATA_TYPE.POLL) {
                console.log("inside polling");
                dispatch(setIsPolling(true));
              }
              dispatch(setType(parsedData.type));
              dispatch(footerVisibility(true));
              dispatch(setContent(parsedData));
            } else if (parsedData.type === POLL_DATA_TYPE.DISCLAIMER) {
              dispatch(setType(parsedData.type));
              dispatch(setContent(parsedData));
              dispatch(
                setFooter({
                  meetingName: parsedData.content.eventName,
                  region: parsedData.content.location.city,
                  country: parsedData.content.location.country,
                  date: parsedData.content.eventData,
                  show: false,
                })
              );
              // dispatch(fetchAndPublishFooterData()).then((d) =>
              //   dispatch(footerVisibility(true))
              // );
            } else if (parsedData.type === POLL_DATA_TYPE.CASE) {
              dispatch(setType(parsedData.type));
              dispatch(setContent(parsedData));
              dispatch(setIsPolling(false));
            } else if (parsedData.type === POLL_DATA_TYPE.POLL_CLOSE) {
              showAlert(parsedData.content.text);
              dispatch(caseData());
            } else if (
              parsedData.type === POLL_DATA_TYPE.EVENT_EXPIRED ||
              parsedData.type === POLL_DATA_TYPE.EVENT_NOT_FOUND
            ) {
              dispatch(setType(parsedData.type));
              dispatch(setContent(parsedData));
            } else if (parsedData.type === POLL_DATA_TYPE.HEARTBEAT) {
              dispatch(setPing(window.performance.now()));
            }
          }
        };
      }
    } catch (err) {
      console.log(err);
    }
  }
);
