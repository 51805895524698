import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: '',
    isDisplayed: false
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        showAlert: (state, message) => {
            return {
                ...state,
                message: message.payload,
                isDisplayed: true
            }
        },
        hideAlert: (state) => {
            return {
                ...state,
                isDisplayed: false
            }
        }
    }
});

export const {showAlert, hideAlert} = alertSlice.actions;

export default alertSlice.reducer;