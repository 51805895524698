import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { CHAPTER_SELECTION_TYPE } from "../../../../api/constants";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { caseData, postPollData } from "../../../../redux/thunk";
import "./MultiChoice.scss";
import _ from "lodash";

const MultiChoice = () => {
  const dispatch = useAppDispatch();
  const contentState = useAppSelector((state) => state.contentReducer);
  const eventState = useAppSelector((state) => state.eventReducer);
  let options = contentState.content.options;

  const refOverflowingContainer = useRef();
  const refOverflowingValuesContainer = useRef();
  const [overflowActive, setOverflowActive] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isExclusiveValueAdded, setIsExclusiveValueAdded] = useState(false);

  if (!Array.isArray(options)) {
    options = [options];
  }

  const [checked, setChecked] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];

    if (event.target.checked) {
      if (contentState.exclusivePolling) {
        checked.forEach((val) => updatedList.splice(checked.indexOf(val), 1));
        updatedList = [event.target.value];
      } else {
        const value = event.target.getAttribute("name");
        if (value.slice(0, 3) == "***") {
          setIsExclusiveValueAdded(true);
          checked.forEach((val) => updatedList.splice(checked.indexOf(val), 1));
          updatedList = [event.target.value];
        } else {
          if (isExclusiveValueAdded) {
            checked.forEach((val) =>
              updatedList.splice(checked.indexOf(val), 1)
            );
            updatedList = [event.target.value];
            setIsExclusiveValueAdded(false);
          } else {
            updatedList = [...checked, event.target.value];
          }
        }
      }
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const isSelected = (id) => {
    return checked.indexOf(id) !== -1;
  };

  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      }
    }
    return key;
  };

  const handleSubmission = (event) => {
    setSubmitted(true);
    const pushResult = {
      pollId: contentState.pollId,
      clientId: eventState.clientId,
      type: contentState.header.type,
      answers: checked,
    };
    dispatch(postPollData(pushResult)).then(dispatch(caseData()));
  };

  function isOverflowActive(container, values) {
    return container.offsetHeight < values.scrollHeight;
  }

  useEffect(() => {
    if (
      isOverflowActive(
        refOverflowingContainer.current,
        refOverflowingValuesContainer.current
      )
    ) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false);
    }
  }, []);

  return (
    <div ref={refOverflowingContainer} className="multi-choice-container">
      <div
        ref={refOverflowingValuesContainer}
        className={classNames("multi-choice-values-container", "webkit-full")}
      >
        {options.map((choice, index) => {
          return (
            <div className={classNames("multi-choice", "")} key={index}>
              <input
                name={choice.option}
                value={choice.id}
                checked={isSelected(choice.id)}
                type="checkbox"
                onChange={handleCheck}
              />
              <label
                className={classNames(
                  "choice-label",
                  isSelected(choice.id) ? "selection-background" : ""
                )}
              >
                {'   ' + choice.option.replace("***", "")}
              </label>
            </div>
          );
        })}
        {/* !overflowActive ? "button-position" : "" */}
        <button
          className={classNames("button-multi-choice-position", "")}
          disabled={!checked.length > 0 && !submitted}
          onClick={handleSubmission}
        >
          {getTranslation("Submit")}
        </button>
      </div>
    </div>
  );
};

export default MultiChoice;
