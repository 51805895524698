import { useAppSelector } from "../../../../hooks/hook";
import "./errors.scss";

const Error = () => {
  const contentState = useAppSelector((state) => state.contentReducer);
  const dictionaryState = useAppSelector((state) => state.dictionaryReducer);

  const getTranslation = (key) => {
    if (dictionaryState.keywords.name != "English") {
      const other = dictionaryState.other;
      if (_.capitalize(key) in other) {
        return other[_.capitalize(key)];
      } else if (_.camelCase(key) in other) {
        return other[_.capitalize(key)];
      } else if (key in other) {
        return other[key];
      }
    }
    return key;
  };

  return (
    <div>
      <span className="error-msg">
        {getTranslation(contentState.content.text)}
      </span>
    </div>
  );
};

export default Error;
